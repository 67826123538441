export const intro = {
  title: "Sose Inc",
  text: ["You own your data. We help you use it."],
  button: [],
  logo: "sose_short_text.png",
  image: "developer.svg",
  titleSize: true,
  id: "intro",
};

export const about = {
  title: "",
  text: [
    "We're a team of data scientists and software engineers based in Boulder, CO. Since 2016, we've been helping companies in finance, health care, and eCommerce manage, analyze, and put their data to work through custom software development and consulting.",
  ],
  button: "",
  titleSize: true,
  id: "about",
};

export const consulting = {
  title: "Consulting",
  text: [
    "Every company has data, but not every company needs an in-house data science and software team.",
    "That's where we come in. Our model lets you own your data while we help you make the most of it.",
  ],
  icon: "spreadsheet_dark_blue.svg",
  section: [
    {
      title: "Fractional Data Scientist",
      content:
        "Improve your data management and uncover the critical insights you need to make data-driven decisions.",
      // icon: "notes.svg", <- this is an option
      icon: "",
    },
    {
      title: "Software Contracting",
      content:
        "Develop the internal tools or external products you need to accelerate your business and win customers.",
      icon: "",
    },
    {
      title: "Scalable Resources",
      content:
        "Use what you need, and only what you need. You can reap the benefits of an external software team by allowing us to scale up or down as you need it.",
      icon: "",
    },
    {
      title: "Do What You Do Best",
      content:
        "Technology should help your business, not slow you down. Let us handle your software, website, and data so that you can focus on what you do best.",
      icon: "",
    },
  ],
  id: "consulting",
};

export const machineLearning = {
  title: "Machine Learning / AI",
  text: [
    "Whether you want your spreadsheets to stop weighing you down or you want to leverage cutting-edge machine learning to add value to your business, our team can help you put your data to work for you. Use machine learning and AI to make data-driven decisions and give your customers an experience they will love.",
  ],
  icon: "mind_map_dark_blue.svg",
  id: "machine-learning",
};

export const appDevelopment = {
  title: "Application Development",
  text: [
    "End-to-end software development, from inception to deployment. With experience developing enterprise applications in finance and healthcare, let us make your web or mobile app vision a reality with the highest level of data protection.",
  ],
  icon: "application_dark_blue.svg",
  id: "app-development",
};

export const cloudHosting = {
  title: "Cloud Hosting",
  text: [
    "Make your application, company website, or internal company documents available to those who need it and protected from those who don't. Let us take the complexity out of secure hosting so you never have to think about it.",
  ],
  icon: "server_dark_blue.svg",
  id: "cloud-hosting",
};

export const contentBlocks = [machineLearning, appDevelopment, cloudHosting];

export const team = {
  title: "Our Team",
  id: "team",
  text: [""],
  profiles: [
    {
      name: "Charlie Keyes",
      title: "CEO",
      image: "/img/team/charlie.jpg",
      email: "c.keyes@soseintel.com",
    },
    {
      name: "Rose Klassen",
      title: "Project Manager",
      image: "/img/team/rose.jpg",
      email: "r.klassen@soseintel.com",
    },
    {
      name: "Kat Leight",
      title: "Software Engineer",
      image: "/img/team/kat.jpg",
      email: "r.klassen@soseintel.com",
    },
    {
      name: "Jeff Gronewold",
      title: "Software Engineer",
      image: "/img/team/jeff_g.jpg",
      email: "jeff.gronewold@soseintel.com",
    },
    {
      name: "Sara Tankard",
      title: "Front End Developer",
      image: "/img/team/sara.jpg",
      email: "s.tankard@soseintel.com",
    },
    {
      name: "Jeff Klassen",
      title: "DevOps Engineer",
      image: "/img/team/jeff.png",
      email: "j.klassen@soseintel.com",
    },
    {
      name: "Ben Dunn",
      title: "Software Engineer",
      image: "/img/team/ben.jpeg",
      email: "b.dunn@soseintel.com",
    },
  ],
};

export const contact = {
  title: "Let's get in touch",
  text: [
    "Have a project in mind? Want to know more about how we can help your company? Drop us a note.",
  ],
};
