import { createGlobalStyle } from "styled-components";
import { soseDarkBlue } from "./soseStyles";
const Styles = createGlobalStyle`

    body,
    html,
    a {
        font-family: 'Ubuntu', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    htitle {
        font-family: 'Ubuntu', sans-serif;
        color: #0a1f44;
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 0;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
        }
    }
    h5 {
        font-size: 2,5rem;
    }
    h4 {
        font-size: 3rem;
    }
    h3 {
        font-size: 3.5rem;
    }
    h2 {
        font-size: 4rem;
    }

    p {
        color: #343D48;
        font-size: 1rem;
    }
    
    ptitle {
        color: #343D48;
        font-size: 1.2rem;
    }
    
    htitle {
        font-size: 3rem;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        text-align: center;
        padding-top: 2.5rem;
        padding-right: 2rem;
    }

    .anticon,
    .ant-notification-notice-icon-success {
        color: ${soseDarkBlue};
    }
`;

export default Styles;
