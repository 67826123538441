import * as S from "./styles";

import { Col, Row } from "antd";
import { Fragment, lazy } from "react";

import Fade from "react-reveal/Fade";
import { withTranslation } from "react-i18next";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Container = lazy(() => import("../../common/Container"));

const Footer = ({ t }) => {
  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <Fragment>
      <Fade bottom>
        <S.Footer>
          <Container>
            <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Language>{t("Sose Inc")}</S.Language>
                <S.Para>Based in Boulder, CO</S.Para>
              </Col>
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Language>{t("Contact")}</S.Language>
                <a href="mailto:c.keyes@soseintel.com">
                  <S.Chat>{t(`Charlie Keyes | CEO`)}</S.Chat>
                </a>
              </Col>
              {/* <Col lg={6} md={6} sm={12} xs={24}>
                <S.Title>{t("Company")}</S.Title> */}
              {/* <S.Large left="true" to="/">
                  {t("About")}
                </S.Large> */}
              {/* <S.Large to="/careers">
                  {t("Careers")}
                </S.Large> */}
              {/* <S.Large left="true" to="/">
                  {t("Blog")}
                </S.Large> */}
              {/* </Col> */}
            </Row>
            {/* <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Empty />
                <S.Language>{t("ADDRESS")}</S.Language>
                <S.Para>Boulder, CO</S.Para>
              </Col>
            </Row> */}
          </Container>
        </S.Footer>
        <S.Extra>
          <Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              <S.NavLink to="/">
                <S.LogoContainer>
                  <SvgIcon
                    src="sose_logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </S.LogoContainer>
              </S.NavLink>
              <S.FooterContainer>
                <SocialLink
                  href="https://github.com/SoseIntelWorks"
                  src="github.svg"
                />
                <SocialLink
                  href="https://www.linkedin.com/company/sose-intelworks/"
                  src="linkedin.svg"
                />
              </S.FooterContainer>
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default withTranslation()(Footer);
